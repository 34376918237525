import jwt_decode from "jwt-decode";
import HttpService from "./HttpService";

const tokenStore = "token";

const AuthenticationService = {
    async isSignedIn() {
        var token = this.fetchToken();
        if (token === null) {
            return false;
        }

        var decodedToken = jwt_decode(token.accessToken);
        var expiryDateTime = new Date(0);
        expiryDateTime.setUTCSeconds(decodedToken.exp);
        expiryDateTime = expiryDateTime.toUTCString();
        const currentDateTime = new Date().toUTCString();

        // recast as Date() object as comparing UTC strings is problematic when the dates span over two days
        if (new Date(expiryDateTime) < new Date(currentDateTime)) {
            console.log("Token expired. Attempting refresh");
            let response = await this.refreshToken();
            return response;
        } else {
            console.log("Token active");
            return true;
        }
    },

    async refreshToken() {
        try {
            let response = await HttpService.refreshToken();
            if (response.status === 200 && response.error.length === 0) {
                this.storeToken(response.data);
                console.log("Token refreshed");
                return true;
            }
            else {
                console.log("Token cannot be refreshed");
            }
            return false;
        } catch (err) {
            console.log("Error refreshing token");
            return false;
        }
    },

    storeToken(token) {
        if (token !== null && token !== undefined) {
            localStorage.setItem(tokenStore, JSON.stringify(token));
            console.log("Token stored");
        } else {
            console.log("Token bot stored");
        }
    },

    async revokeToken() {
        try {
            let response = await HttpService.revokeToken();
            if (response.status === 200) {
                localStorage.removeItem(tokenStore);
                console.log("Token revoked");
                return true;
            }
            return false;
        } catch (err) {
            console.log("Token cannot be revoked");
            console.log(err);
            return false;
        }
    },

    fetchToken() {
        return JSON.parse(localStorage.getItem(tokenStore));
    },
};

export default AuthenticationService;

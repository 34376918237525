import axios from "axios";
import AuthenticationService from "./AuthenticationService";

// const baseUrl = `http://192.168.1.182:4000/api`;
const baseUrl = `https://app.5v5ide.com/api`;

const league = `${baseUrl}/league`;
const match = `${baseUrl}/match`;
const result = `${baseUrl}/result`;
const player = `${baseUrl}/player`;
const playerStats = `${baseUrl}/player/stats`;
const allPlayerStats = `${baseUrl}/player/allplayerstats`;
const awards = `${baseUrl}/award/all`;
const tapTheBall = `${baseUrl}/taptheball`;
const playerAwards = `${baseUrl}/award/player`;
const editDetails = `${baseUrl}/player/editdetails`;
const nationalities = `${baseUrl}/player/nationalities`;
const getAvatar = `${baseUrl}/player/avatar`;
const updateAvatar = `${baseUrl}/player/avatar/upload`;
const updatePlayer = `${baseUrl}/player/editplayer`;
const register = `${baseUrl}/user/register`;
const verifyEmail = `${baseUrl}/user/verify-email`;
const authenticate = `${baseUrl}/user/authenticate`;
const refreshToken = `${baseUrl}/user/refresh-token`;
const revokeToken = `${baseUrl}/user/revoke-token`;
const unlinkedPlayers = `${baseUrl}/player/unlinkedplayers`;
const userplayerlink = `${baseUrl}/user/userplayerlink`;
const linkplayer = `${baseUrl}/user/linkplayer`;
const rejectplayerlink = `${baseUrl}/user/rejectplayerlink`;
const linkplayerstatus = `${baseUrl}/user/linkplayerstatus`;
const forgotpassword = `${baseUrl}/user/forgot-password`;
const resetpassword = `${baseUrl}/user/reset-password`;

const configuration = {
    withBearerToken() {
        var token = AuthenticationService.fetchToken();

        if (token !== null && token.accessToken.length > 0) {
            return {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                },
                withCredentials: true,
            };
        }
        return "";
    },

    withCredentials() {
        return { withCredentials: true };
    },
};

const request = {
    postNoAuth(url, payload) {
        return axios.post(url, payload, configuration.withCredentials());
    },

    postWithAuth(url, payload) {
        return axios.post(url, payload, configuration.withBearerToken());
    },

    postWithCredentials(url, payload) {
        return axios.post(url, payload, configuration.withCredentials());
    },

    getNoAuth(url, param) {
        if (param === undefined) {
            return axios.get(url);
        } else {
            return axios.get(`${url}/${param}`);
        }
    },

    getWithAuth(url, param) {
        if (param === undefined) {
            return axios.get(url, configuration.withBearerToken());
        } else {
            return axios.get(
                `${url}/${param}`,
                configuration.withBearerToken()
            );
        }
    },
};

const HttpService = {
    baseUrl() {
        return baseUrl;
    },

    getLeagues() {
        return request.getNoAuth(league);
    },

    getMatchDates(param) {
        return request.getNoAuth(match, param);
    },

    getResult(param) {
        return request.getNoAuth(result, param);
    },

    getPlayer(param) {
        return request.getNoAuth(player, param);
    },

    getPlayerStats(param) {
        return request.getNoAuth(playerStats, param);
    },

    getAllPlayersStats(param) {
        return request.postNoAuth(allPlayerStats, param);
    },

    getAwards() {
        return request.getNoAuth(awards);
    },

    getPlayerAwards(param) {
        return request.getNoAuth(playerAwards, param);
    },

    getUserScore() {
        return request.getWithAuth(tapTheBall);
    },

    getTopTenUserScores(param) {
        return request.getWithAuth(tapTheBall, param);
    },

    getTopTenUserScoresNoAuth(param) {
        return request.getNoAuth(tapTheBall, param);
    },

    updateUserScore(payload) {
        return request.postWithAuth(tapTheBall, payload);
    },

    getEditPlayer(param) {
        return request.getWithAuth(editDetails, param);
    },

    getNationalities() {
        return request.getNoAuth(nationalities);
    },

    getAvatar(param) {
        return request.getNoAuth(getAvatar, param);
    },

    updateAvatar(payload) {
        return request.postWithAuth(updateAvatar, payload);
    },

    updatePlayer(payload) {
        return request.postWithAuth(updatePlayer, payload);
    },

    createAccount(payload) {
        return request.postNoAuth(register, payload);
    },

    verifyEmail(payload) {
        return request.postNoAuth(verifyEmail, payload);
    },

    authenticate(payload) {
        return request.postNoAuth(authenticate, payload);
    },

    refreshToken() {
        return request.postWithCredentials(refreshToken);
    },

    revokeToken() {
        return request.postWithAuth(revokeToken);
    },

    getUnlinkedPlayers() {
        return request.getWithAuth(unlinkedPlayers);
    },

    userPlayerLink(payload) {
        return request.postWithAuth(userplayerlink, payload);
    },

    linkPlayer(payload) {
        return request.postWithAuth(linkplayer, payload);
    },

    rejectPlayerLink(payload) {
        return request.postWithAuth(rejectplayerlink, payload);
    },

    getLinkPlayerApprovalStatus() {
        return request.getWithAuth(linkplayerstatus);
    },

    forgotPassword(payload) {
        return request.postNoAuth(forgotpassword, payload);
    },

    resetPassword(payload) {
        return request.postNoAuth(resetpassword, payload);
    },
};

export default HttpService;

import React, { Component } from "react";
import "./Summary.css";

export default class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expand };
    }

    expandShrinkTable() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
        }
    }

    renderTableHeader() {
        return (
            <tr onClick={() => this.expandShrinkTable()}>
                <th className="top-header top-left"></th>
                <th key={0} className="top-header top-central">
                    Summary
                </th>
                <th className="top-header top-right">
                    <span
                        className={
                            this.state.expanded
                                ? "chevron-open"
                                : "chevron-close"
                        }>
                        ▼
                    </span>
                </th>
            </tr>
        );
    }

    render() {
        if (this.props.summary === undefined) {
            return <div></div>;
        }

        if (this.props.summary.length === 0) {
            return (
                <div className="disable-text-select">
                    <div className="summary center stack-tables">
                        <table className="top-border">
                            <tbody >
                                {this.renderTableHeader()}
                            </tbody>
                        </table>
                        <table className="top-border">
                            <tbody className="no-data-summary-table summary-border collapsed-height">
                                <tr className="row">
                                    <td className="no-data-summary-row-center summary-font-size">
                                        No Summary
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return (
            <div className="disable-text-select">
                <div className="summary center stack-tables">
                    <table className="top-border">
                        <tbody >
                            {this.renderTableHeader()}
                        </tbody>
                    </table>
                    <table className="top-border">
                        <tbody className={this.state.expanded ? "summary-table summary-border" : "summary-table summary-border collapsed-height"}>
                            <tr className="row">
                                <td className="summary-row-center summary-font-size">
                                    {this.props.summary}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import HttpService from "../../services/HttpService";
import MediaQuery from "react-responsive";
import DateSelector from "../dateselector/DateSelector";
import ScorerTable from "../scorertable/ScorerTable";
import Summary from "../summary/Summary";
import TopPlayer from "../topplayer/TopPlayer";
import TeamPlayers from "../teamplayers/TeamPlayers";
import KickOffTime from "../kickofftime/KickOffTime";
import "./Match.css";

export default class Match extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMatchGuid: "",
            dateOptions: [],
            result: {},
            loading: true,
        };
        this.changeDate = this.changeDate.bind(this);
        this.fetchResult = this.fetchResult.bind(this);
    }

    async componentDidMount() {
        await this.fetchMatchDates();
        this.fetchUrlResult();
    }

    parseUrlParam() {
        let url = this.props.location.pathname;
        let matchGuid = url.split("/").pop();
        let guidPattern =
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (guidPattern.test(matchGuid)) {
            return matchGuid;
        }
        return null;
    }

    fetchUrlResult() {
        let matchGuid = this.parseUrlParam();
        if (matchGuid) {
            this.setState({ selectedMatchGuid: matchGuid });
            this.fetchResult(matchGuid);
        } else {
            this.fetchResult(this.state.dateOptions[0]?.guid);
        }
    }

    changeDate(selectedDate) {
        this.setState(
            { selectedMatchGuid: selectedDate },
            this.fetchResult(selectedDate)
        );
    }

    async fetchMatchDates() {
        let url = this.props.location.pathname;
        let urlLeague = url.split("/")[1];

        if (urlLeague !== this.props.league.route) {
            await HttpService.getLeagues().then(async (res) => {
                const allLeagues = res.data;

                const desiredLeague = allLeagues.find(
                    (league) => league.route === urlLeague
                );

                let leagueId = 1;
                if (desiredLeague !== undefined) {
                    leagueId = desiredLeague.id;
                    this.props.setAppLeague(
                        desiredLeague.name,
                        desiredLeague.route,
                        leagueId
                    );
                }

                const response = await HttpService.getMatchDates(leagueId);
                this.setState({ dateOptions: response.data });
            });
        } else {
            const leagueId = this.props.league.id;
            const response = await HttpService.getMatchDates(leagueId);
            this.setState({ dateOptions: response.data });
        }
    }

    fetchResult(matchGuid) {
        this.setState({ loading: true }, () => {
            HttpService.getResult(matchGuid).then((res) => {
                this.setState({ loading: false });
                const result = res.data;
                this.setState({ result });
            });
        });
    }

    render() {
        return (
            <div>
                <div className="match-league-name">
                    {this.props.league.name}
                </div>
                <DateSelector
                    league={this.props.league}
                    history={this.props.history}
                    dateOptions={this.state.dateOptions}
                    value={this.state.selectedMatchGuid}
                    dateSelected={this.changeDate}
                />
                {this.state.loading ? (
                    <div className="loading-gif-container">
                        <img
                            className="loading-gif"
                            src={require("../../assets/images/5v5ide_gif.gif")}
                            alt=""></img>
                    </div>
                ) : (
                    <div>
                        <MediaQuery maxWidth={699}>
                            <div className="center">
                                <div className="match match-grid">
                                    <div className="margin-top-15">
                                        <KickOffTime
                                            startTime={
                                                this.state.result.startTime
                                            }
                                            finishTime={
                                                this.state.result.finishTime
                                            }
                                        />
                                    </div>
                                    <div className="scorer-grid">
                                        <ScorerTable
                                            league={this.props.league}
                                            history={this.props.history}
                                            result={this.state.result}
                                        />
                                    </div>
                                    <div className="top-goals-grid margin-top-15">
                                        <TopPlayer
                                            league={this.props.league}
                                            history={this.props.history}
                                            topType="Scorer"
                                            data={
                                                this.state.result.topGoalscorers
                                            }
                                        />
                                    </div>
                                    <div className="top-assists-grid margin-top-15">
                                        <TopPlayer
                                            league={this.props.league}
                                            history={this.props.history}
                                            topType="Assister"
                                            data={this.state.result.topAssists}
                                        />
                                    </div>
                                    <div className="summary-grid margin-top-15">
                                        <Summary
                                            league={this.props.league}
                                            history={this.props.history}
                                            summary={this.state.result.summary}
                                        />
                                    </div>
                                    <div className="teams-grid margin-top-15 margin-bottom-20">
                                        <TeamPlayers
                                            league={this.props.league}
                                            history={this.props.history}
                                            homeTeam={
                                                this.state.result.homeTeam
                                            }
                                            awayTeam={
                                                this.state.result.awayTeam
                                            }
                                            players={this.state.result.players}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={700} maxWidth={1199}>
                            <div className="match two-column-grid">
                                <div className="score-teams">
                                    <div className="margin-top-15">
                                        <KickOffTime
                                            startTime={
                                                this.state.result.startTime
                                            }
                                            finishTime={
                                                this.state.result.finishTime
                                            }
                                        />
                                    </div>
                                    <div className="scorer-grid padding-horizontal-3">
                                        <ScorerTable
                                            league={this.props.league}
                                            history={this.props.history}
                                            result={this.state.result}
                                        />
                                    </div>
                                    <div className="teams-grid padding-horizontal-3 margin-top-15">
                                        <TeamPlayers
                                            league={this.props.league}
                                            history={this.props.history}
                                            homeTeam={
                                                this.state.result.homeTeam
                                            }
                                            awayTeam={
                                                this.state.result.awayTeam
                                            }
                                            players={this.state.result.players}
                                        />
                                    </div>
                                </div>
                                <div className="scorers-assists margin-top-30">
                                    <div className="top-goals-grid padding-horizontal-3">
                                        <TopPlayer
                                            league={this.props.league}
                                            history={this.props.history}
                                            topType="Scorer"
                                            data={
                                                this.state.result.topGoalscorers
                                            }
                                        />
                                    </div>
                                    <div className="top-assists-grid padding-horizontal-3 margin-top-15">
                                        <TopPlayer
                                            league={this.props.league}
                                            history={this.props.history}
                                            topType="Assister"
                                            data={this.state.result.topAssists}
                                        />
                                    </div>
                                    <div className="summary-grid padding-horizontal-3 margin-top-15">
                                        <Summary
                                            league={this.props.league}
                                            history={this.props.history}
                                            summary={this.state.result.summary}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={1200}>
                            <div className="center">
                                <div className="match">
                                    <div className="fit-content padding-horizontal-3">
                                        <div className="margin-top-minus-15">
                                            <KickOffTime
                                                startTime={
                                                    this.state.result.startTime
                                                }
                                                finishTime={
                                                    this.state.result.finishTime
                                                }
                                            />
                                        </div>
                                        <ScorerTable
                                            league={this.props.league}
                                            history={this.props.history}
                                            result={this.state.result}
                                            expand={true}
                                        />
                                    </div>
                                    <div>
                                        <div className="fit-content padding-horizontal-3">
                                            <TopPlayer
                                                league={this.props.league}
                                                history={this.props.history}
                                                topType="Scorer"
                                                data={
                                                    this.state.result.topGoalscorers
                                                }
                                                expand={true}
                                            />
                                        </div>
                                        <div className="fit-content padding-horizontal-3 margin-top-15">
                                            <TopPlayer
                                                league={this.props.league}
                                                history={this.props.history}
                                                topType="Assister"
                                                data={this.state.result.topAssists}
                                                expand={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="fit-content padding-horizontal-3">
                                        <Summary
                                            league={this.props.league}
                                            history={this.props.history}
                                            summary={this.state.result.summary}
                                            expand={true}
                                        />
                                    </div>
                                    <div className="fit-content padding-horizontal-3">
                                        <TeamPlayers
                                            league={this.props.league}
                                            history={this.props.history}
                                            homeTeam={
                                                this.state.result.homeTeam
                                            }
                                            awayTeam={
                                                this.state.result.awayTeam
                                            }
                                            players={this.state.result.players}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                )}
            </div>
        );
    }
}
